import { useState } from 'react'

import siloVirtualLogo from '../../assets/silo_virtual.png'
import iconeSiloVirtual from '../../assets/silo_virtual_icon.png'

import './styles.css'

export default function Header() {
  const [width, setWidth] = useState(window.innerWidth)

  window.addEventListener('resize', (e) => setWidth(window.innerWidth))

  const handleScroll = (section: string) => {
    const HTMLElement = document.getElementById(section)

    if (HTMLElement) {
      window.scrollTo({
        top: HTMLElement.getBoundingClientRect().top + window.scrollY,
        behavior: 'smooth',
      })
    }
  }

  return (
    <header>
      <div className="container">
        {width > 500 ? (
          <img src={siloVirtualLogo} alt="Silo Virtual" />
        ) : (
          <img
            src={iconeSiloVirtual}
            alt="Silo Virtual"
            className="small-image "
          />
        )}

        <ul>
          <li onClick={() => handleScroll('want__app')}>Baixe o APP</li>
          <li onClick={() => handleScroll('other__solutions')}>
            Outras Soluções
          </li>
          <li onClick={() => handleScroll('footer')}>Contato</li>
        </ul>

        {width < 768 && (
          <>
            <input type="checkbox" id="change-hamburguer" />

            <a className="hamburguer" href="#">
              <span></span>
              <label htmlFor="change-hamburguer"></label>
            </a>

            <div className="menu">
              <div className="links">
                <div className="link">
                  <span onClick={() => handleScroll('want__app')}>
                    Baixe o APP
                  </span>
                </div>
                <div className="link">
                  <span onClick={() => handleScroll('other__solutions')}>
                    Outras Soluções
                  </span>
                </div>
                <div className="link">
                  <span onClick={() => handleScroll('footer')}>Contato</span>
                </div>
              </div>
              <div className="socials">
                <h4>Siga a RuralHub</h4>

                <div className="icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    data-testid="icon"
                    style={{ width: 32, height: 32 }}
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/brasilsistemassinop/'
                      )
                    }
                  >
                    <path
                      fill="url(#paint0_linear_562_13831)"
                      fill-rule="evenodd"
                      d="M12 3c-2.444 0-2.75.01-3.71.054-.958.044-1.612.196-2.184.419A4.407 4.407 0 004.51 4.51c-.5.5-.808 1.003-1.038 1.594-.224.573-.376 1.227-.419 2.185C3.011 9.249 3 9.556 3 12s.011 2.75.055 3.71c.043.958.195 1.612.417 2.184a4.41 4.41 0 001.039 1.595c.5.5 1.002.809 1.593 1.038.573.223 1.227.375 2.185.419.96.043 1.267.054 3.71.054 2.445 0 2.751-.01 3.711-.054.958-.044 1.613-.196 2.186-.419a4.403 4.403 0 001.593-1.038c.5-.5.808-1.003 1.038-1.594.222-.573.374-1.227.419-2.185.043-.96.054-1.266.054-3.71s-.011-2.751-.054-3.711c-.045-.958-.197-1.612-.419-2.184A4.416 4.416 0 0019.49 4.51c-.5-.5-1.001-.808-1.594-1.037-.574-.223-1.228-.375-2.186-.419-.96-.043-1.266-.054-3.71-.054zm-.3 1.622h.3c2.404 0 2.689.008 3.638.052.877.04 1.354.186 1.67.31.42.163.72.358 1.035.673.315.315.51.615.674 1.035.123.317.27.793.31 1.67.043.95.052 1.234.052 3.636s-.01 2.687-.052 3.636c-.04.877-.187 1.353-.31 1.67-.163.42-.359.72-.674 1.034-.315.315-.614.51-1.034.673-.317.124-.794.27-1.671.31-.949.043-1.234.053-3.637.053s-2.688-.01-3.637-.053c-.877-.04-1.354-.187-1.67-.31a2.79 2.79 0 01-1.036-.673 2.788 2.788 0 01-.674-1.035c-.123-.316-.27-.793-.31-1.67-.043-.949-.051-1.234-.051-3.637 0-2.404.008-2.687.052-3.636.04-.877.186-1.354.31-1.67.163-.42.358-.72.673-1.036.315-.315.615-.51 1.035-.673.317-.124.794-.27 1.671-.31.83-.038 1.152-.05 2.83-.051v.002h.507zm4.025 2.574a1.08 1.08 0 112.16 0 1.08 1.08 0 01-2.16 0zm-3.724.182a4.622 4.622 0 100 9.244 4.622 4.622 0 000-9.244zM15 12a3 3 0 10-6 0 3 3 0 006 0z"
                      clip-rule="evenodd"
                    ></path>
                    <defs>
                      <linearGradient
                        id="paint0_linear_562_13831"
                        x1="12"
                        x2="12"
                        y1="3"
                        y2="21"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#405DE6"></stop>
                        <stop offset=".724" stop-color="#F807A6"></stop>
                        <stop offset="1" stop-color="#FFC52C"></stop>
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    data-testid="icon"
                    style={{ width: 32, height: 32 }}
                    onClick={() =>
                      window.open('https://www.facebook.com/BrasilSistemas')
                    }
                  >
                    <path
                      fill="#1877F2"
                      d="M15.476 12.932l.414-2.874h-2.593V8.194c0-.786.361-1.553 1.523-1.553H16V4.194S14.93 4 13.907 4c-2.137 0-3.533 1.377-3.533 3.868v2.19H8v2.874h2.374v6.948a8.874 8.874 0 002.923 0v-6.948h2.179z"
                    ></path>
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    data-testid="icon"
                    style={{ width: 32, height: 32 }}
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/channel/UCZCHEyBNQVsFueN1LGIoaGg'
                      )
                    }
                  >
                    <path
                      fill="red"
                      d="M18.666 7.874c-.184-.738-.727-1.319-1.415-1.516C16.003 6 11 6 11 6s-5.003 0-6.251.358c-.689.197-1.23.778-1.415 1.516C3 9.21 3 12 3 12s0 2.79.334 4.126c.184.738.726 1.319 1.415 1.516C5.997 18 11 18 11 18s5.003 0 6.251-.358c.688-.197 1.23-.778 1.415-1.516C19 14.79 19 12 19 12s0-2.79-.334-4.126z"
                    ></path>
                    <path fill="#fff" d="M10 15V9l4 3-4 3z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  )
}
