import { useState } from 'react'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import doublePhone from '../../assets/double_phone.png'
import farmBordered from '../../assets/farm_bordered.png'
import siloRounded from '../../assets/silo_rounded.png'
import farmerRounded from '../../assets/farmer_rounded.png'
import siloVirtualLogo from '../../assets/silo_virtual.png'
import playStoreBadge from '../../assets/play_store_badge.png'
import appleStoreBadge from '../../assets/apple_store_badge.png'
import logoRural from '../../assets/logo_rural.png'
import arrowRightUp from '../../assets/icons/arrow_right_up.png'
import whatsappIcon from '../../assets/icons/whatsapp.png'
import arrowUp from '../../assets/icons/arrow_up.png'
import chemicalIcon from '../../assets/icons/chemical.png'
import clockIcon from '../../assets/icons/clock.png'
import phoneIcon from '../../assets/icons/phone.png'
import arrowRightCardIcon from '../../assets/icons/arrow_right.png'
import download from '../../assets/icons/download.png'

import './styles.css'

export default function Home() {
  const [width, setWidth] = useState(window.innerWidth)

  const [showArrowUpBadge, setShowArrowUpBadge] = useState(false)

  window.addEventListener('resize', (e) => {
    setWidth(window.innerWidth)
  })

  window.addEventListener('scroll', (e) => {
    if (window.scrollY > 200) {
      setShowArrowUpBadge(true)
    } else if (window.scrollY < 200) {
      setShowArrowUpBadge(false)
    }
  })

  const handleScroll = (section: string) => {
    const HTMLElement = document.getElementById(section)

    if (HTMLElement) {
      window.scrollTo({
        top: HTMLElement.getBoundingClientRect().top + window.scrollY,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div>
      <Header />

      <section className="home" id="home">
        <div className="container">
          <div className="home__image">
            <img src={doublePhone} alt="Aplicativo" />
          </div>

          <div className="info">
            <h1>
              O seu armazém <br /> <h1 className="green">100%</h1> online.
            </h1>

            <h4>
              O <b>Silo Virtual</b> é uma forma de acompanhar o contrato do
              agricultor com o seu armazém de forma rápida e prática, auxiliando
              no controle geral da fazenda.
            </h4>

            <button
              className="button__redirecter"
              onClick={() => handleScroll('about__app')}
            >
              Saiba Mais
            </button>
          </div>
        </div>
      </section>

      <section className="about__app" id="about__app">
        <img src={farmBordered} alt="Campo" />
        <div className="solid">
          <h2>
            Visualização de contratos, classificações de entrada e saída, lotes
            de saída, acompanhamento do estoque e dos débitos e créditos de cada
            cliente.
          </h2>
        </div>
      </section>

      <section className="app__benefits" id="app__benefits">
        <div className="container">
          <h1>
            Por que usar o <span>Silo Virtual</span>?
          </h1>

          <div className="benefit">
            <img src={siloRounded} alt="Silo" />

            <div className="info">
              <h2>
                Para o <span>Armazém.</span>
              </h2>

              <span>Troca de informaçoes em tempo real com seus clientes.</span>
              <span>Controle de visualização.</span>
            </div>
          </div>

          <div className="benefit">
            {width < 737 && <img src={farmerRounded} alt="Silo" />}
            <div className="info info__right">
              <h2>
                Para o <span>Agricultor.</span>
              </h2>

              <span>Acompanhamento em tempo real do saldo físico/fiscal.</span>
              <span>Visualização de saldo de estoque.</span>
              <span>Histórico de safras.</span>
            </div>

            {width >= 737 && <img src={farmerRounded} alt="Silo" />}
          </div>

          <button
            className="button__redirecter"
            onClick={() => handleScroll('want__app')}
          >
            Quero o meu APP
          </button>
        </div>
      </section>

      <section className="want__app" id="want__app">
        <div className="container">
          <img src={siloVirtualLogo} alt="Silo Virtual" />

          <div className="header">
            <h1>
              Tenha um aplicativo <br /> do seu armazém.
            </h1>

            <h5>
              <hr />
              Entregamos a você um aplicativo com a cara do <br /> seu armazém,
              exclusivo para os seus clientes.
            </h5>
          </div>

          <div className="cards">
            <div className="card">
              <div className="card__header">
                <img
                  className="card__header__icon"
                  src={chemicalIcon}
                  alt="Experimente"
                />

                <img
                  className="card__header__arrow"
                  src={arrowRightCardIcon}
                  alt="Adiante"
                />
              </div>

              <h1>Experimente</h1>

              <p>
                Baixe o aplicativo de demonstração gratuita, acesse como
                visitante e conheça o nosso produto.
              </p>
            </div>

            <div className="card">
              <div className="card__header">
                <img
                  className="card__header__icon"
                  src={phoneIcon}
                  alt="Smartphone"
                />

                <img
                  className="card__header__arrow"
                  src={arrowRightCardIcon}
                  alt="Adiante"
                />
              </div>

              <h1>Solicite o seu APP</h1>

              <p>
                Para aproveitar dos benefícios do Silo Virtual entre em contato
                conosco para solicitar um aplicativo exclusivo para o seu
                armazém.
              </p>
            </div>

            <div className="card">
              <div className="card__header">
                <img
                  className="card__header__icon"
                  src={clockIcon}
                  alt="Agilidade"
                />
              </div>

              <h1>Facilidade</h1>

              <p>
                Com o seu aplicativo em mãos, você pode conectar e liberar os
                seus clientes para que acompanhem os processos de forma 100%
                virtual e prática.
              </p>
            </div>
          </div>

          <div className="footer">
            <div className="download__text">
              <img src={download} alt="Download" />

              <h1>
                Baixe agora e <br /> comece a utilizar.
              </h1>
            </div>

            <div className="store__badges">
              <img
                src={playStoreBadge}
                alt="Google Play App"
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=br.com.brst.SiloVirtual'
                  )
                }
              />
              <img src={appleStoreBadge} alt="Apple Store App" />
            </div>
          </div>
        </div>
      </section>

      <section className="other__solutions" id="other__solutions">
        <div className="text">
          <h1>
            Conheça nossas <span>outras soluções</span>
          </h1>

          <h5>
            Atuamos em diversos outros segmentos e possuímos ferramentas para
            transporte, finanças e corretoras.
          </h5>

          <span onClick={() => window.open('https://brst.com.br/')}>
            Saiba mais
            <img src={arrowRightUp} alt="Seta pra Cima" />
          </span>
        </div>

        <img src={logoRural} alt="Rural Hub" className="logo__enterprise" />
      </section>

      <Footer />

      {showArrowUpBadge && (
        <div
          className="scroll__to__top"
          id="scroll__to__top"
          onClick={() => handleScroll('home')}
        >
          <img src={arrowUp} alt="Flecha pra cima" />
        </div>
      )}

      <div
        className="whatsapp__contact"
        id="whatsapp__contact"
        onClick={() =>
          window.open(
            'https://wa.me/556699984060?text=Olá, estou entrando em contato pelo site do Silo Virtual, gostaria de saber mais a respeito.'
          )
        }
      >
        <img src={whatsappIcon} alt="WhatsApp" />
      </div>
    </div>
  )
}
